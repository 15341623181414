<template>
  <v-container fluid>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="2"
        offset-sm="3"
        offset-md="4"
        offset-lg="5"
      >
        <v-text-field
          v-model="search"
          label="Pesquisa"
          append-icon="mdi-magnify"
          single-line
          hide-details
          @change="consulta"
        />
      </v-col>
    </v-row>
    <v-row v-if="Object.keys(agendamento).length > 0">
      <v-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        offset-sm="2"
        offset-md="3"
        offset-lg="4"
      >
        <v-row>
          <v-col cols="12" sm="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field
              v-model="agendamento.status"
              label="Status"
              readonly
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConsultaGerenciamentoServico',
  data() {
    return {
      search: '',
      loading: false,
      page: {
        title: 'Consulta',
      },
      agendamento: {},
      breadcrumbs: [
        {
          text: 'Consulta',
          disabled: false,
        },
      ],
    }
  },
  async created() {},

  methods: {
    ...mapActions('template', ['errorMessage']),
    ...mapActions('patio', ['getConsultaGerenciamentoServico']),
    async consulta(q) {
      this.loading = true
      this.agendamento = await this.getConsultaGerenciamentoServico(q)
      this.loading = false
    },
  },
  components: {},
}
</script>
